<script setup lang="ts">
  import { useBreakpoints, useScrollDetector, useScrollWidth, useCustomerOrderAbility, useCustomerAccepted } from '$storefront';
  import { onMounted, ref, onBeforeUnmount, watch, computed, type PropType } from 'vue';
  import { route } from '@plenny/visitor';

  type ReviewsSummary = {
    1: { count: number, percent: number },
    2: { count: number, percent: number },
    3: { count: number, percent: number },
    4: { count: number, percent: number },
    5: { count: number, percent: number },
  }

  const props = defineProps({
    breadcrumbs: { type: Array, required: true },
    sections: { type: Array, required: true },
    product: { type: Object, required: true },
    variant: { type: Object, required: false, default: null },
    brand: Object,
    category: Object,
    recommended: Array,
    posts: Array,
    page: Object,
    review_available: { type: Boolean, required: true },
    reviews: { type: Object, required: true },
    reviews_summary: { type: Object as PropType<ReviewsSummary>, required: true },
  });

  const breakpoints = useBreakpoints();
  const scroll = useScrollWidth();
  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();

  const variant = ref(props.variant);
  const wrapperHtmlElement = ref();
  const wrapperIsNotIntersecting = ref(false);
  const visible = ref(false);

  const scrollWidth = computed(() => scroll.value + 'px');

  useScrollDetector({
    threshold: 8,
    callback: ({ down }) => {
      if (down) {
        if (wrapperIsNotIntersecting.value) {
          visible.value = true;
        }
      } else {
        visible.value = false;
      }
    },
  });

  let observer;

  onMounted(() => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        wrapperIsNotIntersecting.value = !entry.isIntersecting;
      });
    }, {
      root: document.body,
      threshold: 0.25,
    });

    observer.observe(wrapperHtmlElement.value);
  });

  onBeforeUnmount(() => {
    observer.disconnect();
  });

  watch(() => variant.value.slug, (value, oldValue) => {
    if (oldValue && value !== oldValue) {
      let loc = route('web.product.show', { slug: value });

      window.history.replaceState(loc, '', loc);
    }
  });

  watch(() => props.variant, (value) => {
    variant.value = value;
  });
</script>
<template>
  <div class="container">
    <SfBreadcrumbs :breadcrumbs class="product-show__breadcrumbs" />
  </div>
  <div :class="{ 'product-banner--visible': visible }" :aria-hidden="!visible" class="product-banner">
    <span class="product-banner__line"></span>
    <div class="container product-banner__container">
      <div class="product-banner__info">
        <!--        <MediaPicture class="product-banner__image" :photo="product.photo" sizes="200x200 2x, 100x100" />-->
        <div class="product-banner__title" v-html="variant?.title || product.title" />
      </div>
      <div class="product-banner__price" v-if="canCustomerOrder">
        <SfProductPrice :price="variant || product" current />
        <SfCartButton v-model:variant="variant" :product>
          <SfButton :rounded="!breakpoints.lg" :square="!breakpoints.lg">
            <template #before>
              <IconPlus />
            </template>
            <template v-if="breakpoints.lg" #default>
              {{ $t('Dodaj do koszyka') }}
            </template>
          </SfButton>
        </SfCartButton>
      </div>
      <template v-else>
        <VisitorLink transparent small v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" class="product-banner__sign-in" rel="nofollow">
          {{ $t('Zaloguj się aby zobaczyć ceny') + '.' }}
        </VisitorLink>
        <span v-else class="product-banner__inactive-account">
          {{ $t('Twoje konto nie jest jeszcze aktywne') + '.' }}
        </span>
      </template>
    </div>
    <div class="product-banner__nav">
      <ProductDescriptionNav :product :recommended :sections class="container" />
    </div>
  </div>
  <div ref="wrapperHtmlElement" class="product-details-container">
    <ProductDetail v-model:variant="variant" :brand :category :page :product :sections />
  </div>
  <ProductDescription :product :recommended :reviews :sections :reviewAvailable="review_available" :reviewsSummary="reviews_summary" />
</template>
<style lang="scss" scoped>
  .product-banner {
    --space: v-bind(scrollWidth);

    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    right: var(--space);
    left: 0;
    z-index: 1000;
    transform: translateY(-120%);
    background-color: var(--color-main-black);
    color: var(--color-primary-white);
    box-shadow: var(--box-shadow-primary);
    transition: var(--transition-primary);

    &--visible {
      transform: translateY(0);
    }

    &__line {
      display: block;
      height: 3px;
      background-image: linear-gradient(90deg, var(--color-primary-blue) 25%, var(--color-primary-red) 25%, var(--color-primary-red) 50%, var(--color-primary-blue) 50%, var(--color-primary-blue) 75%, var(--color-primary-red) 75%);
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 0.5rem 0;
    }

    &__info {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      gap: 20px;
    }

    &__nav {
      background-color: var(--color-white);
      box-shadow: var(--box-shadow-primary);
    }

    &__price {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;
      --sf-product-price-current-color: var(--color-primary-white);

      :deep(.cart-button) {
        --sf-product-price-per-color: var(--color-primary-white);
        --sf-input-border-active: var(--color-primary-white);
        --sf-btn-border-hover: var(--color-primary-white);
      }

      :deep(> .price) {
        --sf-product-price-per-color: var(--color-primary-white);
      }
    }

    //:deep(.product-banner__image) {
    //  display: flex;
    //
    //  img {
    //    width: 75px;
    //    aspect-ratio: 1 / 1;
    //  }
    //}

    &__title {
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 1.5rem;
      font-weight: var(--font-weight-700);
    }

    &__inactive-account,
    &__sign-in {
      text-align: center;
    }

    &__sign-in {
      color: var(--color-primary-white);
      text-decoration: underline;
      transition: color 0.2s ease;

      @media(hover: hover) {
        &:hover {
          color: var(--color-medium-gray);
        }
      }
    }
  }

  .product-show__breadcrumbs {
    --sf-breadcrumbs-padding-y: 1.5rem
  }

  .product-details-container {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 992px) {
    .product-banner {
      --sf-product-price-current-font-size: 0.875rem;
      --sf-product-price-per-font-sizemenu-drawer__wrapper: 0.625rem;

      &__title {
        font-size: 0.75rem;
      }

      &__container {
        padding: 0.625rem;
      }

      &__inactive-account,
      &__sign-in {
        font-size: 0.75rem;
      }
    }

    .product-details-container {
      margin-bottom: 1.5rem;
    }
  }
</style>

