<script lang="ts" setup>
  import { useBreakpoints, useCustomerOrderAbility, useCustomerAccepted } from '$storefront';
  import { route, $post, useShared, $delete } from '@plenny/visitor';
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    product: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: 'h2',
    },
    horizontal: {
      type: Boolean,
    },
  });

  const shared = useShared();
  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const breakpoints = useBreakpoints();

  const target = computed(() => {
    return route('web.product.show', { slug: props.product.slug });
  });

  const isCompared = computed(() => {
    if (props.product.variant_id && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(props.product.variant_id);
    } else {
      return false;
    }
  });

  function toggleComparator() {
    if (isCompared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': props.product.variant_id }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': props.product.variant_id }));
    }
  }

  const compareTitle = computed(() => {
    return isCompared.value ? trans('Usuń z porównania') : trans('Dodaj do porównania');
  });

  const hasListAttributes = computed(() => {
    return props.product.list_attributes && props.product.list_attributes.length > 0;
  });
</script>
<template>
  <article :class="{ 'product-card--horizontal': horizontal, 'has-labels': product.is_new || product.discount_value }" class="product-card" tabindex="0" @click="$redirect(target)">
    <div class="product-card__image">
      <MediaPicture :photo="product.photo" sizes="600x600 2x, 300x300" />
      <SfCartButton v-if="!horizontal && breakpoints.lg && canCustomerOrder" :product="product">
        <SfButton :square="!breakpoints.lg" class="product-card__buy-btn product-card__buy-btn--dynamic" primary>
          <template #before>
            <IconPlus class="product-card__buy-btn-icon" />
          </template>
          <template v-if="breakpoints.lg" #default>
            {{ $t('Dodaj do koszyka') }}
          </template>
        </SfButton>
      </SfCartButton>
    </div>
    <div class="product-card__content">
      <div class="product-card__info">
        <header>
          <component :is="header" class="product-card__title">
            <VisitorLink :href="target">
              {{ product.title }}
            </VisitorLink>
          </component>
        </header>
        <dl v-if="hasListAttributes" v-show="horizontal" class="product-card__description-list ">
          <div v-for="attribute in product.list_attributes" :key="attribute.name" class="product-card__details-column">
            <dt class="product-card__description-term">{{ attribute.name }}:</dt>
            <dd :title="attribute.value" class="product-card__description-details">{{ attribute.value }}</dd>
          </div>
        </dl>
      </div>
      <div class="product-card__actions">
        <div class="product-card__actions-top">
          <div class="product-card__price-icons">
            <div class="product-card__price">
              <SfProductPrice v-if="canCustomerOrder" :price="product" class="product-card__product-price" />
              <SfProductAvailability v-if="canCustomerOrder" :product="product" class="product-card__product-availability" />
              <div class="product-card__icons">
                <slot name="icons">
                  <SfWishlistButton :variant="product.variant_id" class="product-card__wishlist" tabindex="0" />
                  <SfButton :class="{'compared': isCompared}" :title="compareTitle" :variant="product.variant_id" class="product-card__compare" square tabindex="0" transparent @click.stop="toggleComparator">
                    <IconArrowDownUp />
                  </SfButton>
                </slot>
              </div>
            </div>
            <SfProductOmnibus v-if="canCustomerOrder" :price="product" class="product-card__omnibus" />
          </div>
          <div class="product-card__brand-sku">
            <dl v-show="horizontal" class="product-card__description-list ">
              <div v-if="product.brand_name" class="product-card__details-column">
                <dt class="product-card__description-term">{{ $t('Marka') + ': ' }}</dt>
                <dd class="product-card__description-details">{{ product.brand_name }}</dd>
              </div>
              <div v-if="product.ean" class="product-card__details-column">
                <dt class="product-card__description-term">{{ $t('EAN') + ': ' }}</dt>
                <dd class="product-card__description-details">{{ product.ean }}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div v-if="(horizontal || !breakpoints.lg) && canCustomerOrder" class="product-card__buttons">
          <SfCartButton :product="product">
            <SfButton :square="!breakpoints.lg" class="product-card__buy-btn product-card__buy-btn--static" primary>
              <template #before>
                <IconPlus class="product-card__buy-btn-icon" />
              </template>
              <template v-if="breakpoints.lg" #default>
                {{ $t('Dodaj do koszyka') }}
              </template>
            </SfButton>
          </SfCartButton>
          <SfButton :href="target" class="product-card__more-btn">{{ $t('Dowiedz się więcej') }}</SfButton>
        </div>
        <div v-if="!canCustomerOrder" class="product-card__quest">
          <span v-if="isCustomerAccepted === undefined" @click.stop="void 0" class="product-card__sign-in">
            <VisitorLink :href="route('web.account.session.create')" rel="nofollow" class="product-card__sign-in-link">
              {{ $t('Zaloguj się aby zobaczyć ceny') + '.' }}
            </VisitorLink>
          </span>
          <span v-else class="product-card__inactive-account">
            {{ $t('Twoje konto nie jest jeszcze aktywne') + '.' }}
          </span>
        </div>
      </div>
    </div>
    <div class="product-card__labels">
      <SfProductTag :visible="!!product.is_new" tagType="novelty">
        {{ $t('Nowość') }}
      </SfProductTag>
      <SfProductTag :visible="+product.price_gross !== +product.regular_gross" tagType="discount">
        {{ $t('Promocja') }}
      </SfProductTag>
    </div>
  </article>
</template>
<style lang="scss" scoped>
  .product-card__product-availability {
    margin: 0.5rem 0;
  }

  .product-card {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--color-primary-white);
    cursor: pointer;

    &__image {
      position: relative;
      width: 100%;

      :deep(picture) {
        display: inline-block;
        width: 100%;

        img {
          aspect-ratio: 1;
        }
      }
    }

    &__wishlist :deep(.wishlist__button),
    .product-card__compare {
      --sf-btn-padding-y: 0.5rem;
      --sf-btn-padding-y-sm: 0.5rem;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 1rem;
    }

    &__title {
      display: -webkit-box;
      font-size: 1rem;
      font-weight: var(--font-weight-500);
      line-height: 1.5;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    &__icons {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition-property: opacity;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
    }

    &__description-list {
      display: grid;
      grid-template-columns: 1fr;
      font-size: 0.875rem;
      line-height: 1.5;
      color: var(--color-main-black);

      .product-card__details-column {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      .product-card__description-term {
        display: inline;
        margin-right: 0.25rem;
      }

      .product-card__description-details {
        display: inline;
        font-weight: var(--font-weight-500);
      }
    }

    .product-card__compare {
      --sf-btn-font-size: 1.5rem;
    }

    &__wishlist :deep(.wishlist__button svg) {
      --sf-btn-font-size-icon: 1.5rem;
    }

    &__wishlist :deep(.wishlist__button),
    .product-card__compare {
      opacity: 0;
      background-color: white;

      &:focus {
        opacity: 1;
      }
    }

    &__compare.compared {
      color: var(--color-primary-blue);
    }

    &__labels {
      position: absolute;
      top: 1rem;
      left: 1rem;
      display: flex;
      gap: 0.5rem;
    }

    &__details-wrapper,
    &__brand-sku,
    &__more-btn {
      display: none;
    }

    &__buy-btn--dynamic {
      position: absolute;
      right: 1rem;
      bottom: 0;
      opacity: 0;

      transition-property: opacity, bottom, background-color;

      &:focus {
        bottom: 1rem;
        opacity: 1;
      }
    }

    &__buy-btn-icon {
      font-size: 0.875rem;
    }

    &:hover,
    &:focus {
      > .product-card__content > .product-card__actions > .product-card__actions-top > .product-card__price-icons > .product-card__price > .product-card__icons > {

        .product-card__wishlist :deep(.wishlist__button),
        .product-card__compare {
          opacity: 1;
        }
      }

      > .product-card__image > :deep(.cart-button) > .product-card__buy-btn {
        opacity: 1;
        bottom: 1rem;
      }
    }

    @media(min-width: 993px) {

      &--horizontal {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        gap: 1.5rem;
        padding: 1.5rem;

        > .product-card__image {
          width: unset;

          > :deep(picture) {
            width: 300px;
          }
        }

        > .product-card__content {
          display: grid;
          grid-template-columns:  1fr 280px;
          gap: 1.5rem;

          > .product-card__info {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            > header > .product-card__title {
              font-size: 1.125rem;
              font-weight: var(--font-weight-700);
            }
          }

          //
          //.product-card__description-list {
          //  display: grid;
          //  grid-template-columns: 1fr;
          //  font-size: 1rem;
          //  line-height: 1.5;
          //  color: var(--color-main-black);
          //
          //
          //  .product-card__details-column {
          //    display: -webkit-box;
          //    -webkit-box-orient: vertical;
          //    -webkit-line-clamp: 1;
          //    overflow: hidden;
          //  }
          //
          //  .product-card__description-term {
          //    display: inline;
          //    margin-right: 0.25rem;
          //  }
          //
          //  .product-card__description-details {
          //    display: inline;
          //    font-weight: var(--font-weight-500);
          //  }
          //}

          > .product-card__actions {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1.5rem;

            > .product-card__actions-top {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              padding-right: 2.625rem;

              > .product-card__price-icons {
                > .product-card__price {
                  > .product-card__product-price {
                    --sf-product-price-catalog-font-weight: var(--font-weight-500);
                    --sf-product-price-current-font-size: 2rem;
                    --sf-product-price-current-font-line-height: 1.2;
                    --sf-product-price-per-font-size: 0.875rem;
                    --sf-omnibus-font-size: 0.75rem;

                    padding-right: 2.5rem;
                  }

                  > .product-card__icons {
                    > .product-card__wishlist > :deep(.wishlist__button),
                    > .product-card__compare {
                      opacity: 1;
                    }
                  }
                }

                > .product-card__omnibus {
                  padding-top: 0.375rem;
                }
              }

              > .product-card__brand-sku {
                display: unset;
              }
            }

            > .product-card__buttons {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              > .product-card__more-btn {
                display: inline-flex;
              }
            }
          }
        }
      }
    }

    &__actions {
      flex-wrap: wrap;
    }

    &__quest {
      width: 100%;
    }

    &__inactive-account,
    &__sign-in {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      font-size: 0.875rem;
    }

    &__sign-in-link {
      color: var(--color-primary-blue);
      font-weight: var(--font-weight-500);
      text-decoration: underline;
      transition: color 0.2s ease;

      @media(hover: hover) {
        &:hover {
          color: var(--color-main-black);
        }
      }
    }
  }

  @media (min-width: 993px) and (max-width: 1400px) {
    .product-card {
      &--horizontal {
        gap: 1.5rem;
        padding: 1.5rem;

        > .product-card__image {
          width: unset;

          > :deep(picture) {
            width: 200px;
          }
        }

        > .product-card__content {
          grid-template-columns:  1fr;
          gap: 1.5rem;

          > .product-card__actions {
            > .product-card__actions-top {
              > .product-card__price-icons {
                > .product-card__price {
                  display: flex;
                  justify-content: space-between;
                  gap: 1rem;

                  > .product-card__product-price {
                    padding-right: 0;
                  }

                  > .product-card__icons {
                    position: static;
                    display: flex;
                    flex-direction: row;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {

    .product-card {
      padding: 0.625rem;

      &__content {
        gap: 0.875rem;
      }

      &__wishlist :deep(.wishlist__button),
      .product-card__compare {
        --sf-btn-padding-y: 0.5rem;
        --sf-btn-padding-y-sm: 0.5rem;
        opacity: 1;
      }

      &__icons {
        top: 0.5rem;
        right: 0.5rem;
      }

      &__actions {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 0.5rem;
      }

      &__labels {
        left: 0;
        top: 0.625rem;
        flex-direction: column;
        gap: 0.375rem;
      }

      &__description-list {
        font-size: 0.875rem;
        margin-top: 0.625rem;
      }

      &__sign-in-link {
        padding: 0.75rem 0;
      }

      &--horizontal > .product-card__content {
        gap: 0.125rem;

        > .product-card__actions > .product-card__actions-top {
          display: flex;
          flex-direction: column-reverse;
          gap: 0.875rem;

          > .product-card__brand-sku {
            display: unset;

            > .product-card__description-list {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
</style>

